import React from 'react';

const Merci = () => {
  return (
    <>
      <div id="slogan" className="effect1">
        <h1>Confirmation</h1>
      </div>
      <div id="infoComp">
        <div id="confirmation">
          <p>
            Nous vous remercions de nous avoir contacté. Votre courriel a bien
            été envoyé. Nous vous contacterons dans les plus brefs délais.
          </p>
        </div>
      </div>
    </>
  );
};

Merci.propTypes = {};

export default Merci;
