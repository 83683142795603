import React from 'react';

import { PAGES } from '../utils/utils';
import Merci from '../components/fr/merci';
import Layout from '../components/layout';
import SEO from '../components/seo';

const MerciPage = () => (
  <Layout page={PAGES.MERCI}>
    <SEO title="Merci!" />
    <Merci />
  </Layout>
);

export default MerciPage;
